var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{ref:("DatePicker-" + _vm.hash),class:[
    'datepicker__wrapper datepicker__wrapper',
    {
      'datepicker__wrapper--booking': _vm.bookings.length > 0,
      datepicker__fullview: _vm.alwaysVisible
    }
  ]},[(_vm.isOpen && _vm.isMobile)?_c('div',{staticClass:"datepicker__close-button",on:{"click":_vm.closeMobileDatepicker}},[_c('i',[_vm._v("+")])]):_vm._e(),(!_vm.alwaysVisible)?_c('div',{class:[
      'datepicker__dummy-wrapper',
      { 'datepicker__dummy-wrapper--is-active': _vm.isOpen }
    ],on:{"click":_vm.toggleDatepicker}},[_c('date-input',{staticClass:"datepicker__input--first",attrs:{"i18n":_vm.i18n,"input-date":_vm.formatDate(_vm.checkIn),"input-date-type":"check-in","is-open":_vm.isOpen,"single-day-selection":_vm.singleDaySelection}}),(!_vm.singleDaySelection)?_c('date-input',{attrs:{"i18n":_vm.i18n,"input-date":_vm.formatDate(_vm.checkOut),"input-date-type":"check-out","is-open":_vm.isOpen,"single-day-selection":_vm.singleDaySelection}}):_vm._e()],1):_vm._e(),(_vm.showClearSelectionButton)?_c('div',{staticClass:"datepicker__clear-button",attrs:{"tabindex":"0"},on:{"click":_vm.clearSelection}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 68 68"}},[_c('path',{attrs:{"d":"M6.5 6.5l55 55M61.5 6.5l-55 55"}})])]):_vm._e(),_c('div',{class:[
      'datepicker',
      {
        'show-tooltip': _vm.isMobile && _vm.showCustomTooltip && _vm.hoveringTooltip,
        'datepicker--open': _vm.isOpen && !_vm.alwaysVisible,
        'datepicker--closed': !_vm.isOpen && !_vm.alwaysVisible,
        'datepicker--right': _vm.positionRight
      }
    ]},[(_vm.isOpen && _vm.isMobile)?_c('div',[_c('div',{class:[
          'datepicker__dummy-wrapper datepicker__dummy-wrapper--no-border',
          { 'datepicker__dummy-wrapper--is-active': _vm.isOpen }
        ],on:{"click":_vm.toggleDatepicker}},[_c('date-input',{staticClass:"datepicker__input--first",attrs:{"i18n":_vm.i18n,"input-date":_vm.formatDate(_vm.checkIn),"input-date-type":"check-in","is-open":_vm.isOpen,"single-day-selection":_vm.singleDaySelection}}),(!_vm.singleDaySelection)?_c('date-input',{attrs:{"i18n":_vm.i18n,"input-date":_vm.formatDate(_vm.checkOut),"input-date-type":"check-out","is-open":_vm.isOpen,"single-day-selection":_vm.singleDaySelection}}):_vm._e()],1),(_vm.hoveringTooltip)?_c('div',{staticClass:"datepicker__tooltip--mobile"},[(_vm.customTooltipMessage)?[_vm._v(" "+_vm._s(_vm.cleanString(_vm.customTooltipMessage))+" ")]:_vm._e()],2):_vm._e(),(!_vm.alwaysVisible)?_c('DatePickerWeekRow',{attrs:{"dayNames":_vm.i18n['day-names']}}):_vm._e()],1):_vm._e(),(_vm.isOpen || _vm.alwaysVisible)?_c('div',{staticClass:"datepicker__inner"},[(_vm.isDesktop || _vm.alwaysVisible)?_c('div',{staticClass:"datepicker__header"},[_c('button',{staticClass:"datepicker__month-button datepicker__month-button--prev ",attrs:{"type":"button","tabindex":_vm.isOpen ? 0 : -1,"disabled":_vm.activeMonthIndex === 0},on:{"click":_vm.renderPreviousMonth}}),_c('button',{staticClass:"datepicker__month-button datepicker__month-button--next ",attrs:{"type":"button","disabled":_vm.isPreventedMaxMonth,"tabindex":_vm.isOpen ? 0 : -1},on:{"click":_vm.renderNextMonth}})]):_vm._e(),(
          _vm.isDesktop || _vm.alwaysVisible || (_vm.isMobile && _vm.isOpen && !_vm.alwaysVisible)
        )?_c('div',{ref:"swiperWrapper",class:[
          'datepicker__months',
          { 'datepicker__months--full': _vm.showSingleMonth || _vm.alwaysVisible }
        ]},[(_vm.isMobile)?[(!_vm.alwaysVisible && _vm.activeMonthIndex > 0)?_c('button',{staticClass:"datepicker__button-paginate--mobile datepicker__button-paginate--mobile--top",on:{"click":_vm.renderPreviousMonth}},[_c('i',{staticClass:"arrow"})]):_vm._e()]:_vm._e(),(_vm.months.length > 0)?_vm._l((_vm.paginate),function(month,monthIndex){return _c('div',{key:(_vm.datepickerMonthKey + "-" + month),ref:"datepickerMonth",refInFor:true,staticClass:"datepicker__month",attrs:{"data-key":_vm.activeMonthIndex + month}},[(_vm.months[_vm.activeMonthIndex + month])?[_c('div',{staticClass:"datepicker__month-month"},[_c('span',{staticClass:"datepicker__month-name"},[_vm._v(" "+_vm._s(_vm.months[_vm.activeMonthIndex + month].monthName)+" ")])]),(_vm.isDesktop || _vm.alwaysVisible)?_c('DatePickerWeekRow',{class:{
                  'datepicker__week-row--always-visible': _vm.alwaysVisible
                },attrs:{"dayNames":_vm.i18n['day-names']}}):_vm._e(),_c('div',{staticClass:"container-square"},_vm._l((_vm.months[_vm.activeMonthIndex + month]
                    .days),function(day,dayIndex){return _c('div',{key:(_vm.datepickerDayKey + "-" + monthIndex + "-" + dayIndex),class:[
                    'square',
                    { 'not-in-the-month': !day.belongsToThisMonth }
                  ],on:{"mouseenter":function($event){return _vm.mouseEnterDay(day)}}},[_c('Day',{directives:[{name:"show",rawName:"v-show",value:(day.belongsToThisMonth),expression:"day.belongsToThisMonth"}],attrs:{"activeMonthIndex":_vm.activeMonthIndex,"bookings":_vm.sortBookings,"checkIn":_vm.checkIn,"checkIncheckOutHalfDay":_vm.checkIncheckOutHalfDay,"checkInPeriod":_vm.checkInPeriod,"checkOut":_vm.checkOut,"date":day.date,"disableCheckoutOnCheckin":_vm.disableCheckoutOnCheckin,"duplicateBookingDates":_vm.duplicateBookingDates,"hoveringDate":_vm.hoveringDate,"hoveringPeriod":_vm.hoveringPeriod,"i18n":_vm.i18n,"isDesktop":_vm.isDesktop,"isOpen":_vm.isOpen,"minNightCount":_vm.minNightCount,"nextDisabledDate":_vm.nextDisabledDate,"nextPeriodDisableDates":_vm.nextPeriodDisableDates,"options":_vm.$props,"rate":day.rate,"screenSize":_vm.screenSize,"showCustomTooltip":_vm.showCustomTooltip,"sortedDisabledDates":_vm.sortedDisabledDates,"sortedPeriodDates":_vm.sortedPeriodDates,"tooltipMessage":_vm.customTooltipMessage},on:{"clearSelection":_vm.clearSelection,"bookingClicked":_vm.handleBookingClicked,"dayClicked":_vm.handleDayClick}})],1)}),0)]:_vm._e()],2)}):_vm._e(),(!_vm.alwaysVisible && _vm.isMobile)?_c('button',{staticClass:"datepicker__button-paginate--mobile",attrs:{"disabled":_vm.isPreventedMaxMonth},on:{"click":_vm.renderNextMonth}},[_c('i',{staticClass:"arrow"})]):_vm._e()],2):_vm._e(),_vm._t("content")],2):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }