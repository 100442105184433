
import Vue from "vue";
// import axios from "axios";
import App from "./App.vue";
import store from "./store";


Vue.config.productionTip = false;

let app = new Vue({
  render: h => h(App),
  store
}).$mount("#app");

const el = document.querySelector('#destinations');
try {
  /*
  el.addEventListener('change', function () {
    app.$destroy();

    app = new Vue({
      render: h => h(App),
      store
    }).$mount("#app");
  });
  */

  $(el).on('selectric-change', function () {
    app.$destroy();

    app = new Vue({
      render: h => h(App),
      store
    }).$mount("#app");
  });
} catch (e) { /* nothing */ }
