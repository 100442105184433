import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const lang = document.documentElement.hasAttribute('lang') ? document.documentElement.getAttribute('lang') : 'de';

const translations = {
  de: {
    choose_traveldates: 'Wähle deine Reisedaten aus',
    minimum_stay: 'Mindestaufenthalt erforderlich',
    lang: 'deDE',
    datepickerLang: {
        "check-in": "Ankunft",
        "check-out": "Abreise",
        "day-names": ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
        "month-names": [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember"
        ],

        night: "Nacht",
        nights: "Nächte",
        tooltip: {
          halfDayCheckIn: "Réservation possible",
          halfDayCheckOut: "Réservation possible",
          saturdayToSaturday: "Du samedi au samedi<br/> uniquement",
          sundayToSunday: "Du dimanche au dimanche<br/> uniquement",
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum"
        },
        week: "Woche",
        weeks: "Wochen"
      }
  },
  en: {
    choose_traveldates: 'Choose your travel dates',
    minimum_stay: 'Minimum stay required',
    lang: 'enEN',
    datepickerLang: {
        "check-in": "Arrival",
        "check-out": "Departure",
        "day-names": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        "month-names": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],

        night: "Night",
        nights: "Nights",
        tooltip: {
          halfDayCheckIn: "",
          halfDayCheckOut: "",
          saturdayToSaturday: "",
          sundayToSunday: "",
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum"
        },
        week: "Week",
        weeks: "Weeks"
      }
  }
}


export default new Vuex.Store({
  state: {
    periodDates: [],
    translation: translations[lang]
  },
  mutations: {
    expandPeriodDates(state, newPeriodDates) {
      state.periodDates.push(newPeriodDates);
    }
  },
  actions: {
    expandPeriodDates({ commit }, newPeriodDates) {
      commit("expandPeriodDates", newPeriodDates);
    }
  }
});
