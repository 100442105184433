var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"parent-bullet"},[(_vm.previousBooking && _vm.duplicateBookingDates.includes(_vm.formatDate))?_c('i',{class:[
      'bullet',
      {
        checkInCheckOut: _vm.duplicateBookingDates.includes(_vm.formatDate)
      }
    ],style:(_vm.previousBooking.style)}):_vm._e(),(_vm.previousBooking && _vm.duplicateBookingDates.includes(_vm.formatDate))?_c('i',{staticClass:"pipe checkInCheckOut",style:(_vm.previousBooking.style)}):_vm._e(),(
      _vm.currentBooking &&
        (_vm.currentBooking.checkInDate === _vm.formatDate ||
          _vm.currentBooking.checkOutDate === _vm.formatDate)
    )?_c('i',{class:[
      'bullet',
      {
        checkIn: _vm.currentBooking.checkInDate === _vm.formatDate,
        checkOut: _vm.currentBooking.checkOutDate === _vm.formatDate
      }
    ],style:(_vm.currentBooking.style)}):_vm._e(),(_vm.currentBooking)?_c('i',{class:[
      'pipe',
      {
        checkIn: _vm.currentBooking.checkInDate === _vm.formatDate,
        checkOut: _vm.currentBooking.checkOutDate === _vm.formatDate
      }
    ],style:(_vm.currentBooking.style)}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }