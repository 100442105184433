<template>
  <div id="app" ref="app" :class="{ 'no-click': !datePickerOpen }">
    <div class="vue-container" v-if="datePickerOpen">
      <div class="vue-container__header">
        <h1 class="vue-container__title">{{ $store.state.translation.choose_traveldates}}</h1>
        <div class="close" @click="toggleGlobalOpen">
          <svg
            class="close-svg-container"
            width="35px"
            height="35px"
            viewBox="0 0 35 35"
          >
            <g
              id="close-svg"
              transform="translate(-1296.000000, -121.000000)"
              fill="#EE7F00"
            >
              <g id="Group-15" transform="translate(1296.627417, 121.372583)">
                <polygon
                  id="Rectangle"
                  transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
                  points="-5.53902865 15.4973981 39.5390287 15.4973981 39.5390287 18.5026019 -5.53902865 18.5026019"
                ></polygon>
                <polygon
                  id="Rectangle"
                  transform="translate(17.000000, 17.000000) rotate(-135.000000) translate(-17.000000, -17.000000) "
                  points="-5.53902865 15.4973981 39.5390287 15.4973981 39.5390287 18.5026019 -5.53902865 18.5026019"
                ></polygon>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <DatePicker
        ref="DatePicker"
        showYear
        :half-day="false"
        :disabledDates="dynamicDisabledDates"
        :lastDateAvailable="lastDateAvailable"
        :format="dateFormat"
        :singleDaySelection="singleDayAllowed"
        :startingDateValue="startingDateValue"
        :endingDateValue="endingDateValue"
        :periodDates="$store.state.periodDates"
        alwaysVisible
        :firstDayOfWeek="1"
        :i18n="$store.state.translation.datepickerLang"
        class="datepicker"
        :rates="rates"
        @renderNextMonth="renderNextMonth"
        @renderPrevMonth="renderPrevMonth"
        :hoveringTooltip="false"
        :enableCheckout="true"
      />
      <div class="vue-container__legend">
        <!-- <p class="vue-container__red">ausgebucht</p> -->
        <p class="vue-container__yellow">{{ $store.state.translation.minimum_stay }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import fecha from "fecha";
import axios from "axios";
import DatePicker from "./components/DatePicker/index.vue";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      startingDateValue: null,
      endingDateValue: null,
      property: 'schani_wien',
      singleDayAllowed: false,
      datePickerOpen: false,
      isLoading: false,
      isError: false,
      monthRates: [],
      periodDates: [],
      dynamicDisabledDates: [],
      currentYear: fecha.format(new Date(), "YYYY"),
      currentMonth: fecha.format(new Date(), "MM"),
      shownYear: parseInt(fecha.format(new Date(), "YYYY"), 10),
      shownMonth: parseInt(fecha.format(new Date(), "MM"), 10),
      rates: {},
      callingELement: null,
      checkIn: "",
      checkOut: "",
      datePicker: ""
    };
  },
  computed: {
    dateFormat() {
      return "DD/MM/YYYY";
    },
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    }
  },
  watch: {
    datePicker: {
      handler() {
        if (this.datePicker.checkIn) {
          this.checkIn = fecha.format(this.datePicker.checkIn, "isoDate");
        }
        if (this.datePicker.checkOut) {
          this.checkOut = fecha.format(this.datePicker.checkOut, "isoDate");
        }
      },
      deep: true
    },
    // rates() {
    //   this.loadRates()
    // },
    checkOut: {
      handler() {
        const inputCheckin = document.querySelector(".js-datepicker-checkin");
        inputCheckin.value = this.checkIn;
        const textCheckInDate = new Date(this.checkIn);
        let textCheckOutDate;
        const button = document.querySelector(".js-datepicker-button");
        if (!this.singleDayAllowed) {
          const inputCheckout = document.querySelector(
            ".js-datepicker-checkout"
          );
          if (this.checkOut === this.checkIn) {
            const s = this.checkOut;
            const d = new Date(s);
            d.setUTCDate(d.getUTCDate() + 1);
            inputCheckout.value = fecha.format(d, "YYYY-MM-DD");
            textCheckOutDate = d;
          } else {
            inputCheckout.value = this.checkOut;
            textCheckOutDate = new Date(this.checkOut);
          }
          const output = `${fecha.format(
            textCheckInDate,
            "DD.MM.YYYY"
          )} – ${fecha.format(textCheckOutDate, "DD.MM.YYYY")}`;
          if (!button.classList.contains("summary-booking")) {
            button.innerHTML = output;
          }

          if (document.getElementsByClassName('booking-placeholder').length && output && output != ''){
            document.querySelector(".booking-placeholder").placeholder = output;
          }

          this.toggleGlobalOpen();
        } else {
          button.innerHTML = `${fecha.format(textCheckInDate, "DD.MM.YYYY")}`;
          this.toggleGlobalOpen();
        }
      },
      deep: true
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.DatePicker) {
        this.datePicker = this.$refs.DatePicker.$data;
        clearInterval(interval);
      }
    }, 50);

    window.openDatePickerOverlay = this.toggleGlobalOpen;

    const b = document.querySelectorAll(".js-datepicker-button");
    for (let i = 0; i < b.length; i++) {
      b[i].addEventListener("click", window.openDatePickerOverlay);
    }
    const property_select  = document.querySelectorAll("#destinations");

    if($(property_select).val() !== null){
       this.property = $(property_select).val();
    }else{
      this.property = 'schani_wien';
    }




    const property_salon =  document.body.classList.contains('scheme-hotel-salon');
    const property_hbf =  document.body.classList.contains('scheme-hotel-vienna');

    if( property_salon ){
       this.property = 'schani_salon';
    }

    if( property_hbf ){
       this.property = 'schani_wien';
    }

    if( this.property == 'schani_salon'){
      document.documentElement.style.setProperty("--primary-color", "#652F63")
    }else{
      document.documentElement.style.setProperty("--primary-color", "#EE7F00")
    }
    this.loadMonth();
    // setTimeout(() => {
    //   this.loadRates()
    // },50)
  },
  methods: {
    loadMonth() {
      axios
        .get(
          `https://${location.host}/homepage.ajax?hotel_code=${this.property}&year=${this.currentYear}&month=${this.currentMonth}&guests=1`
        )
        .then(
          function(response) {
            const responseObject = response.data.rates;
            for (let i = 0; i < Object.entries(responseObject).length; i++) {
              if (Object.entries(responseObject)[i][1].closed === "true") {
                this.dynamicDisabledDates.push(
                   Object.entries(responseObject)[i][0]
                );
              }
            }
            this.rates = responseObject;
            if (responseObject.periodDates) {
              const responsePeriodDates = responseObject.periodDates;
                this.periodDates = responsePeriodDates;
              for (const date of responsePeriodDates) {
                this.$store.dispatch("expandPeriodDates", date);
              }
            }
          }.bind(this)
        );
      let nextMonth;
      if (parseInt(this.currentMonth) < 9) {
        nextMonth = `0${parseInt(this.currentMonth) + 1}`;
      } else {
        nextMonth = parseInt(this.currentMonth) + 1;
      }
      setTimeout(() => {
        axios
          .get(
            `https://${location.host}/homepage.ajax?hotel_code=${this.property}&year=${this.currentYear}&month=${nextMonth}&guests=1`
          )
          .then(
            function(response) {
              const responseObject = response.data.rates;
              for (let i = 0; i < Object.entries(responseObject).length; i++) {
                if (Object.entries(responseObject)[i][1].closed === "true") {
                  this.dynamicDisabledDates.push(
                    Object.entries(responseObject)[i][0]
                  );
                }
              }
              const currentRates = this.rates;
              this.rates = { ...currentRates, ...responseObject };
              if (responseObject.periodDates) {
                const responsePeriodDates = responseObject.periodDates;
                for (const date of responsePeriodDates) {
                  this.periodDates.push(date);
                  this.$store.dispatch("expandPeriodDates", date);
                }
              }
            }.bind(this)
          );
      }, 20);
    },
    renderPrevMonth() {
      if (this.shownMonth === 1) {
        this.shownMonth = 12;
        this.shownYear -= 1;
      } else {
        this.shownMonth -= 1;
      }
    },
    renderNextMonth() {
      this.shownMonth += 1;
      if (this.shownMonth === 12) {
        this.shownMonth = 0;
        this.shownYear += 1;
      }
      axios
        .get(
          `https://${location.host}/homepage.ajax?hotel_code=${this.property}&year=${
            this.shownYear
          }&month=${this.shownMonth + 1}&guests=1`
        )
        .then(
          function(response) {
            const responseObject = response.data.rates;
            for (let i = 0; i < Object.entries(responseObject).length; i++) {
              if (Object.entries(responseObject)[i][1].closed === "true") {
                this.dynamicDisabledDates.push(
                  Object.entries(responseObject)[i][0]
                );
              }
            }

            const currentRates = this.rates;
            this.rates = { ...currentRates, ...responseObject };
            if (responseObject.periodDates) {
              const responsePeriodDates = responseObject.periodDates;
              for (const date of responsePeriodDates) {
                 this.periodDates.push(date);
                this.$store.dispatch("expandPeriodDates", date);
              }
            }
            window.console.log(this.periodDates);

          }.bind(this)
        );
      // TODO: Workaround Array und nicht Array angleichen
    },
    toggleDatePickerOutside() {
      this.$refs.DatePicker.showDatepicker();
    },
    bookingClicked(event, date, currentBooking) {
    },
    periodSelected(event, checkIn, checkOut) {
    },
    handleCheckIncheckOutHalfDay(checkIncheckOutHalfDay) {
    },
    addYears(dt, n) {
      return new Date(dt.setFullYear(dt.getFullYear() + n));
    },
    validateDateBetweenTwoDates(fromDate, toDate, givenDate) {
      const getvalidDate = d => {
        return new Date(d);
      };

      return (
        getvalidDate(givenDate) <= getvalidDate(toDate) &&
        getvalidDate(givenDate) >= getvalidDate(fromDate)
      );
    },
    dayClicked(date, formatDate, nextDisabledDate) {
    },
    checkInChanged(newDate) {
      this.newCheckInDate = newDate;
    },
    checkOutChanged(newDate) {
      this.newCheckOutDate = newDate;
    },
    checkInDate() {
      return this.checkIn;
    },
    toggleGlobalOpen() {
      this.datePickerOpen = !this.datePickerOpen;
      const placeholder = document.getElementById("datePickerApp");
      const button = document.querySelector(".js-datepicker-button");
      if (button.classList.contains("single-date")) {
        this.singleDayAllowed = true;
      }
      if (document.querySelector(".js-datepicker-checkin")) {
        const checkInValue = document.querySelector(".js-datepicker-checkin")
          .value;
        if (checkInValue) {
          this.startingDateValue = new Date(checkInValue);
        }
      }
      if (document.querySelector(".js-datepicker-checkout")) {
        const checkOutValue = document.querySelector(".js-datepicker-checkout").value;
        if (checkOutValue) {
          this.endingDateValue = new Date(checkOutValue);
        }
      }
      if (placeholder.hasChildNodes()) {

        placeholder.removeChild(this.$refs.app);
      }
      placeholder.appendChild(this.$refs.app);
      const interval = setInterval(() => {
        if (this.$refs.DatePicker) {
          this.datePicker = this.$refs.DatePicker.$data;
          clearInterval(interval);
        }
      }, 50);
      if (this.datePickerOpen) {
        placeholder.style.display = "";
        document.documentElement.classList.add("menu-open");
        document.body.classList.add("menu-open");
      } else {
        placeholder.style.display = "none";
        document.documentElement.classList.remove("menu-open");
        document.body.classList.remove("menu-open");
      }
    }
  }
};
</script>

<style>
html * {
  box-sizing: border-box;
  margin: 0;
}
.box {
  width: 100%;
}

body {
  margin: 0;
}
#app {
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 999999;
  pointer-events: none;
}


.vue-container {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  padding: 5rem 0;
  top: 0;
  left: 0;
  pointer-events: auto;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.vue-container__header {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.vue-container__legend {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
}
.vue-container__red::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: var(--red);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 2rem;
}
.vue-container__yellow::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: var(--yellow);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 2rem;
}
.close {
  position: relative;
  right: 0;
  z-index: 99;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
}
.close-svg-container {
  width: 100%;
  height: 100%;
}
#close-svg {
  fill: var(--primary-color);
}
.no-click {
  pointer-events: none;
}
.datepicker__fullview {
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 70rem;
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .vue-container {
    padding: 2rem;
  }
  .datepicker__fullview {
    max-width: 40rem;
  }
}
</style>
