var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showTooltip && _vm.options.hoveringTooltip)?_c('div',{staticClass:"datepicker__tooltip",domProps:{"innerHTML":_vm._s(_vm.tooltipMessageDisplay)}}):_vm._e(),_c('div',{ref:"day",class:[
      'datepicker__month-day',
      _vm.dayClass,
      _vm.checkinCheckoutClass,
      _vm.bookingClass,
      { 'datepicker__month-day--today': _vm.isToday }
    ],style:(_vm.isYellow
        ? 'background-color: var(--yellow-bg; color: var(--yellow)'
        : ''),attrs:{"tabindex":_vm.tabIndex},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dayClicked($event, _vm.date)}}},[_c('div',{staticClass:"datepicker__month-day-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.dayNumber))]),(_vm.rate && !_vm.isYellow)?_c('div',{staticClass:"datepicker__month-day-wrapper__rate"},[(_vm.rate[1])?_c('small',[_vm._v(_vm._s(_vm.rate[1].price))]):_c('small',[_vm._v(_vm._s(_vm.rate.price))]),_c('small',[_vm._v(_vm._s(_vm.currencySymbol))])]):_vm._e(),(_vm.isYellow)?_c('div',{staticClass:"datepicker__month-day-wrapper__min-nights"},[_c('small',[_vm._v("mind. "+_vm._s(_vm.periodNightCount)+" Nächte")])]):_vm._e()])]),(_vm.currentBooking && !_vm.isDisabled)?_c('BookingBullet',{attrs:{"currentBooking":_vm.currentBooking,"duplicateBookingDates":_vm.duplicateBookingDates,"formatDate":_vm.formatDate,"previousBooking":_vm.previousBooking}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }